export enum MonitorDetailsTypes {
	HAS_NOT_ACCEPTED = 'hasNotAccepted',
	IS_NOT_ON_ROUTE = 'isNotOnRoute',
	HAS_NOT_FINISHED = 'hasNotFinished',
	ON_TIME = 'onTime',
	DELAYED = 'delayed',
	NEW_MESSAGE = 'newMessage',
	REVIEW = 'review',
	CHECK_IN = 'checkIn',
}

export enum MonitorDetailsLabel {
	HAS_NOT_ACCEPTED = 'Not accepted',
	IS_NOT_ON_ROUTE = 'Not on route',
	HAS_NOT_FINISHED = 'Not finished',
	ON_TIME = 'On time',
	DELAYED = 'Delayed 10+',
	NEW_MESSAGE = 'New Message',
	REVIEW = 'Review',
	CHECK_IN = 'Check-In',
}

export enum Division {
	All = 'all',
	Road = 'road',
	Home = 'home',
	Concierge = 'concierge',
	Claims = 'claims',
}

export enum Source {
	CONNECT_APP = 'Connect App',
	HELIOS_DISPATCH = 'Helios Dispatch',
	EOS_DISPATCH = 'eos-client-dispatch',
	QUALITAS_API = 'Qualitas',
	WHATSAPP = 'WhatsApp',
	CSM_API = 'CSM',
	MMM = 'MMM',
	PWA = 'PWA',
	OPTIMA_API = 'OptimaSeguros',
	VOICEBOT = 'Voicebot',
}

export const enum VehicleTypes {
	AUTO = 1,
	MOTO = 2,
}

export enum EmployeeVehicleTypes {
	VIAL = 'vial',
	WHEEL_LIFT_2 = 'wheelLift2Door',
	WHEEL_LIFT_4 = 'wheelLift4Door',
	FLATBED_2 = 'flatbed2Door',
	FLATBED_4 = 'flatbed4Door',
	MOTO = 'moto',
	STORE = 'stores',
}

export const enum OPT_IN_VALUES {
	YES = 'Yes',
	NO = 'No',
}

export enum LocalityType {
	LOCAL = 'local',
	FOREIGN = 'foreign',
}

export enum NoteChatType {
	DRIVER = 'driver',
	CORPORATE_CLIENT = 'corporate_client',
	ALL_CHATS = 'all_chats',
}

export enum BehaviorModalPayment {
	DISPATCH = 'dispatch', //Vista de dispatch
	CHANGE_PAYMENT_METHOD = 'change_payment_method', //Vista de detalle de servicio, cambio de método de pago
	REMAINING_PAYMENT = 'remaining_payment', //Vista de detalle de servicio, pago de saldo restante
	CHANGE_PAYMENT_METHOD_AND_REMAINING_PAYMENT = 'change_payment_method_and_remaining_payment', //Vista de detalle de servicio, cambio de método de pago del saldo restante
	PAY_SERVICE_COST_REMAINING = 'pay_service_cost_remaining', //Vista de detalle de servicio, pago del remaining de un servicio creado con costo 0.
}

export enum AccountCustomerDbType {
	SFTP = 'SFTP',
	EMAIL = 'Email',
	API = 'API',
}
